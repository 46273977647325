var main = require('integrations/main');
/* RVW & SFRA Base Files */

/* Client Custom */
main.baseFiles.base = require('./product/base');
main.baseFiles.cart = require('./cart');
main.baseFiles.saveForLater = require('./product/saveForLater');
main.baseFiles.compare = require('./components/compare');
main.baseFiles.search = require('./components/search');
main.baseFiles.header = require('./components/header');
main.baseFiles.toolTip = require('./components/toolTip');
main.baseFiles.horizontalTabs = require('./components/horizontalTabs');
main.baseFiles.wishlist= require('./wishlist/wishlist').init
main.baseFiles.unavailableProducts = require('./cart/unavailableProducts');
main.baseFiles.taxConfirmationModal = require('./checkout/taxConfirmationModal');
main.baseFiles.shippingMethodToggle = require('./checkout/shippingMethodToggle');
main.baseFiles.checkoutSpinner = require('./checkout/checkoutSpinner');
main.baseFiles.emailSignup = require('./emailSignup/emailSignup');
main.baseFiles.emailSignupModalUtils = require('./components/emailSignupModalUtils');
main.baseFiles.consentTracking = require('./components/consentTracking');
main.baseFiles.marketingCloud = require('./components/marketingCloud');
main.baseFiles.confirmation = require('./order/confirmation');
main.baseFiles.slider = require('./components/slider').init;
main.baseFiles.customizer = require('./customizer/customizer').initCustomizer;
main.baseFiles.miniCart = require('./components/miniCart');
main.baseFiles.menu = require('./components/menu');
main.baseFiles.bootstrapSelect = require('./components/bootstrapSelect');
main.baseFiles.formValidationIcons = require('./components/formValidationIcons');
main.baseFiles.bisn = require('./components/bisn');
main.baseFiles.quickView = require('./product/quickView');
main.baseFiles.tile = require('./product/tile');
main.baseFiles.modal = require('./components/modal').init;
main.baseFiles.yotpoLoyalty = require('./yotpoLoyalty');
main.baseFiles.b2b = require('b2b_core/b2b/global');
main.baseFiles.einsteinRecs = require('./components/einsteinRecs').init;
module.exports = main;
